import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BlogGatewayService } from '../services/blog-gateway.service';
import { Post } from '../types/post';


@Component({
  selector: 'app-post-view',
  templateUrl: './post-view.component.html',
  styleUrls: ['./post-view.component.scss']
})
export class PostViewComponent implements OnInit {
  model = new Post();
  constructor(
    private postgateway: BlogGatewayService,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      const itemid = {itemid: params["itemid"]};
      this.postgateway.getItem(itemid).then(response => {        
        this.model.itemid = response.itemid;
        this.model.setid = response.setid;
        this.model.setname = response.setname;
        this.model.title = response.title;
        this.model.content = JSON.parse(response.content);
        this.model.imagelist = response.imagelist;
        this.model.isfeatured = response.isfeatured;
      });
    });
  }
}
