<section class="section">
    <div class="container">
      <div class="columns is-mobile is-centered">
        <div class="column is-full">
          <div class="tile is-ancestor">
            <div class="tile is-full is-parent">
              <article class="tile is-child smoke post">
                <button mat-stroked-button color="primary" [routerLink]="['/postdeck', model.setid, model.setname]">{{model.setname}}</button>
                <h1 class="title">{{model.title}}</h1>
                <div class="post__content content">
                  <markdown [data]="model.content"></markdown>
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  