import { Component, OnInit } from '@angular/core';
import { BlogGatewayService } from '../services/blog-gateway.service';

export interface Tile {
  color: string;
  cols: number;
  rows: number;
  text: string;
  itemid: string;
}

@Component({
  selector: 'app-posts',
  templateUrl: './posts.component.html',
  styleUrls: ['./posts.component.scss']
})
export class PostsComponent implements OnInit {
  tiles: Tile[] = [];

  constructor(private postgateway: BlogGatewayService) { }

  ngOnInit(): void {
    let col = 1;
    let row = 1;
    let i = 0;
    const colourmap = ['#c7c9d1', '#bfc4d9', '#b3b3b3', '#b8c0e0','#bfbfbf'];
    this.postgateway.itemSetList().then(response => {
      response.forEach(element => {
        if (col === 2){
          this.tiles.push({text: element.setname, itemid: element.itemid, cols: col, rows: row, color: colourmap[i]});
          row += 1;
          col = 1;
        }else{
          this.tiles.push({text: element.setname, itemid: element.itemid, cols: col, rows: row, color: colourmap[i]});
          col += 1;
        }
        if(i === (colourmap.length - 1)){
          i = 0;
        }
        else{
          i += 1;
        }
      });
    });
  }
}
