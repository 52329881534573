import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PostViewComponent } from './post-view/post-view.component';
import { HomeComponent } from './home/home.component';
import { PostDeckComponent } from './post-deck/post-deck.component';
import { PostsComponent } from './posts/posts.component';


const routes: Routes = [  {
  path: '',
  component: HomeComponent
},
{
  path: 'posts', component: PostsComponent
},
{
  path: 'postdeck/:setid/:setname', component: PostDeckComponent
},
{
  path: 'postview/:itemid', component: PostViewComponent
}];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
