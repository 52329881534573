<section class="section">
  <div class="container">
    <div class="columns is-mobile is-centered">
      <div class="column is-full">
        <div class="tile is-ancestor">
          <div class="tile is-full is-parent">
            <article class="tile is-child smoke post">
              <h1 class="title">Certification Journey</h1>
              <div class="post_home">
                <mat-grid-list cols="1" rowHeight="80px" color="primary">
                  <mat-grid-tile
                      *ngFor="let tile of tiles"
                      [colspan]="1"
                      [rowspan]="1"
                      [style.background]="tile.colour"
                      [routerLink]="['/postview', tile.itemid]">
                      <div class="text-inside-grid">{{tile.title}}</div>
                    </mat-grid-tile>
                </mat-grid-list>
              </div>
            </article>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="divlinkedin">
    <script type="text/javascript" src="https://platform.linkedin.com/badges/js/profile.js" async defer></script>
    <div class="LI-profile-badge"  data-version="v1" data-size="medium" data-locale="en_US" data-type="horizontal" data-theme="light" data-vanity="paul-jackson-9817b7144"><a class="LI-simple-link" href='https://au.linkedin.com/in/paul-jackson-9817b7144?trk=profile-badge'>Paul on LinkedIn</a></div>
  </div>
</section>
