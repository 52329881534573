<section class="section">
    <div class="container">
      <div class="columns is-mobile is-centered">
        <div class="column is-full">
          <div class="tile is-ancestor">
            <div class="tile is-full is-parent">
              <article class="tile is-child smoke post">
                <h2 class="title">{{titletext}}</h2>
                <div class="post_deck">
                  <mat-grid-list cols="1" rowHeight="50px" [gutterSize]="'5px'" color="primary">
                    <mat-grid-tile
                        *ngFor="let tile of tiles"
                        [colspan]="1"
                        [rowspan]="1"
                        [style.background]="tile.colour"
                        [routerLink]="['/postview', tile.itemid]"
                        >
                        <div class="text-inside-grid">{{tile.title}}</div>
                      </mat-grid-tile>
                  </mat-grid-list>
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  