import { Injectable } from '@angular/core';
import { API } from 'aws-amplify';
import { Post,PostSetList } from '../types/index';

@Injectable({
  providedIn: 'root'
})
export class BlogGatewayService {

  constructor() { }

  async getItem(itemid): Promise<any>{
    const apiName = 'er4BlogWeb';
    const path = '/getitem';
    let returnvalue: Post;
    const myInit = {
      body: itemid
    };

    await API.post(apiName, path, myInit)
      .then(response => {
        returnvalue = response;
      })
      .catch(error => {
        return error;
      });
    return returnvalue;
  }

  async getIsFeatured(): Promise<any>{
    const apiName = 'er4BlogWeb';
    const path = '/getisfeatured';
    let returnvalue: Post;

    await API.get(apiName, path, '')
      .then(response => {
        returnvalue = response;
      })
      .catch(error => {
        return error;
      });
    return returnvalue;
  }

  async itemSetList(): Promise<any>{
    const apiName = 'er4BlogWeb';
    const path = '/listitemset';
    let returnvalue: PostSetList;

    await API.get(apiName, path, '')
      .then(response => {
        returnvalue = response;
      })
      .catch(error => {
        return error;
      });
    return returnvalue;
  }

  async getItemSet(itemid): Promise<any>{
    const apiName = 'er4BlogWeb';
    const path = '/getitemset';
    const myInit = {
      body: itemid
    };
    let returnvalue: Post;

    await API.post(apiName, path, myInit)
      .then(response => {
        returnvalue = response;
      })
      .catch(error => {
        return error;
      });
    return returnvalue;
  }
}
