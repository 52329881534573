import { Component, OnInit } from '@angular/core';
import { BlogGatewayService } from '../services/blog-gateway.service';

export interface Tile {
  itemid: string;
  isfeatured: boolean;
  setid: string;
  setname: string;
  title: string;
  content: string;
  colour: string;
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  tiles: Tile[] = [];
  constructor(private bloggateway: BlogGatewayService) { }

  ngOnInit(): void {
    let i = 0;
    const colourmap = ['#bebecf', '#abaaf0', '#cecef5', '#a9a9c9', '#e8e8fa'];
    this.bloggateway.getIsFeatured().then(response => {
      response.forEach(element => {
        this.tiles.push({
          itemid: element.itemid,
          isfeatured: element.
          isfeatured,
          setid: element.setid,
          setname: element.setname,
          title: element.title,
          content: element.content,
          colour: colourmap[i]
        });

        if(i === (colourmap.length - 1)){
          i = 0;
        }
        else{
          i += 1;
        }

      });
    });
  }
}
