import { Component, OnInit } from '@angular/core';
import { BlogGatewayService } from '../services/blog-gateway.service';
import { ActivatedRoute } from '@angular/router';

export interface Tile {
  itemid: string;
  setid: string;
  setname: string;
  title: string;
  colour: string;
}

@Component({
  selector: 'app-post-deck',
  templateUrl: './post-deck.component.html',
  styleUrls: ['./post-deck.component.scss']
})
export class PostDeckComponent implements OnInit {
  title = 'er3';
  tiles: Tile[] = [];
  titletext: string;
  constructor(
    private postGatewayService: BlogGatewayService,
    private route: ActivatedRoute) { }

  ngOnInit(): void {

    let i = 0;
    const colourmap = ['#bebecf', '#abaaf0', '#cecef5', '#a9a9c9', '#e8e8fa'];
    this.route.params.subscribe(params => {
      const itemid = {itemid: params["setid"]};
      this.titletext =  params["setname"];
      this.postGatewayService.getItemSet(itemid).then(response => {
        response.forEach(element => {
          this.tiles.push({
            itemid: element.itemid,
            setid: element.setid,
            setname: element.setname,
            title: element.title,
            colour: colourmap[i]
          });
        });

        if(i === (colourmap.length - 1)){
          i = 0;
        }
        else{
          i += 1;
        }
      });
    });
  }
}
